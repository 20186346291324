@import 'src/style/color';

.my-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 13px;
    margin-right: 5%;
}

.my-button {
    border-radius: 20px !important;
    width: 20%;
    height: 50px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    font-size: calc(1vw + 3pt);
    padding: 0 !important;
}

.my-button.confirm {
    background-color: $credem-green;
    color: #fff;
}

.my-button.cancel {
    background-color: $credem-red;
    color: #fff;
}

.my-button.back {
    background-color: #fff;
    color: $credem-green;
}