@import 'src/style/color';

mat-checkbox.mat-checkbox-checked.mat-accent span.mat-checkbox-background {
    background-color: $credem-green;
}

mat-checkbox.mat-checkbox-checked.mat-accent.red-checkbox span.mat-checkbox-background {
    background-color: $credem-red;
}

mat-checkbox.mat-checkbox-disabled {
    cursor: not-allowed;
}

svg.mat-checkbox-checkmark {
    display: none;
}